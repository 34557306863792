import { useEffect } from 'react';

import { json } from '@remix-run/node';
import {
  Link,
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData } from
'@remix-run/react';
import type { LinksFunction, LoaderFunction } from '@remix-run/node';

import { withSentry } from '@sentry/remix';
import { withEmotionCache } from '@emotion/react';
import confirmModalCSS from 'react-confirm-alert/src/react-confirm-alert.css';

import { type ToastMessage, useToast } from './hooks';
import { commitSession, getCurrentSession } from './services/session.server';
import { Button, Card, H1, ToastComponent } from './components';
import tailwindCSS from './styles/tailwind.css';
import sidebarStyes from './styles/sidebar.css';
import stepCounterStyles from './styles/stepCounter.css';
import animatedInputCSS from './styles/loginHero.css';
import icon from '../public/favicon.svg';

export const links: LinksFunction = () => [
{ rel: 'stylesheet', href: tailwindCSS },
{
  rel: 'stylesheet',
  href: 'https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap'
},
{
  rel: 'stylesheet',
  href: sidebarStyes
},
{
  rel: 'stylesheet',
  href: stepCounterStyles
},
{
  rel: 'stylesheet',
  href: confirmModalCSS
},
{
  rel: 'stylesheet',
  href: animatedInputCSS
},
{ rel: 'icon', type: 'image/svg+xml', href: icon }];


type LoaderData = {
  toastMessage: ToastMessage | null;
  GLOBALS: string;
};

export const loader: LoaderFunction = async ({ request }) => {
  const GLOBALS = JSON.stringify({
    RAFFLE_ENV: process.env.RAFFLE_ENV
  });

  const session = await getCurrentSession(request);

  const toastMessage = session.get('toastMessage') as ToastMessage;

  if (!toastMessage) {
    return json<LoaderData>({ toastMessage: null, GLOBALS });
  }

  if (!toastMessage.type) {
    throw new Error('Message should have a type');
  }

  return json<LoaderData>(
    { toastMessage, GLOBALS },
    { headers: { 'Set-Cookie': await commitSession(session) } }
  );
};

type DocumentProps = {
  children: React.ReactNode;
  title?: string;
  GLOBALS?: string;
};
const Document = withEmotionCache(
  (
  { children, title = 'Raffle Insights', GLOBALS }: DocumentProps,
  emotionCache) =>
  {
    useEffect(() => {
      emotionCache.sheet.container = document.head;

      const tags = emotionCache.sheet.tags;
      emotionCache.sheet.flush();
      tags.forEach((tag) => {
        (emotionCache.sheet as any)._insertTag(tag);
      });
    }, [emotionCache.sheet]);

    return (
      <html lang="en">
        <head>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width,initial-scale=1" />
          <title>{title}</title>
          <Meta />
          <link rel="canonical" href="https://app.raffle.ai" />
          <Links />
        </head>
        <body>
          {children}
          <ScrollRestoration />
          <script
            suppressHydrationWarning
            dangerouslySetInnerHTML={{
              __html: `window.GLOBALS=${GLOBALS};`
            }} />

          <Scripts />
          <LiveReload />
        </body>
      </html>);

  }
);

function App() {
  const { toastMessage, GLOBALS } = useLoaderData<LoaderData>();

  useToast(toastMessage);

  return (
    <Document GLOBALS={GLOBALS}>
      <ToastComponent />
      <Outlet />
    </Document>);

}

export default withSentry(App);

export function ErrorBoundary({ error }: {error: Error;}) {
  if (process.env.NODE_ENV === 'development') {
    console.error(error);
  }

  return (
    <Document title="Uh-oh!">
      <div className="flex h-full w-full items-center justify-center">
        <Card className="flex flex-col items-center gap-4">
          <H1>Something went wrong :(</H1>

          <div className="flex items-center gap-4">
            <Button variant="primary" onClick={() => location.reload()}>
              Try again
            </Button>

            <Link to="/logout">
              <Button>Logout</Button>
            </Link>
          </div>
        </Card>
      </div>
    </Document>);

}